// @codekit-append "../bower_components/slick-carousel/slick/slick.min.js";
// @codekit-append "../bower_components/magnific-popup/dist/jquery.magnific-popup.min.js";

var delay = 100;
// Create map objects
var infowindow;
var latlng;
var mapOptions;
var geo;
var map;
var bounds;
// Geocoding
/*
function getAddress(key, next) {
    geo.geocode({address: addresses[key].adresse}, function (results, status) {
        // If that was successful
        if (status == google.maps.GeocoderStatus.OK) {
            // Lets assume that the first marker is the one we want
            var p = results[0].geometry.location;
            var lat=p.lat();
            var lng=p.lng();
            // Create a marker
            createMarker(key, lat, lng);
        }
        // Decode the error status
        else {
            // if we were sending the requests to fast, try this one again and increase the delay
            if (status == google.maps.GeocoderStatus.OVER_QUERY_LIMIT) {
                nextAddress--;
                delay++;
            } else {
                var reason="Code "+status;
            }
        }
        next();
    });
}
*/
// Function to create a marker
function createMarker(key, lat, lng) {
    var marker = new google.maps.Marker({
            position: new google.maps.LatLng(lat, lng),
            map: map,
            zIndex: Math.round(latlng.lat()*-100000)<<5,
            icon: "/sites/all/themes/ehrensache/images/overlay-map-pin.png",
        });

    if(typeof(infowindow) == "object") {
        var html = '';
        html += '<div class="map-popup">';
        html += '   <div class="image-container">';
        html +=         addresses[key].bild;
        html += '   </div>';
        html += '   <div class="info">'+addresses[key].info+'</div>';
        html += '   <a href="'+addresses[key].link+'"><img src="/sites/all/themes/ehrensache/images/icon-filter-show-target.svg" class="pfeil" /></a>';
        html += '   <img src="/sites/all/themes/ehrensache/images/icon-large-pin.svg" class="large-pin" />';
        html += '</div>';
        google.maps.event.addListener(marker, 'click', function() {
            infowindow.setContent(html);
            infowindow.open(map, marker);
        });
    }
    bounds.extend(marker.position);
}
// An array of locations that we want to Geocode
var addresses = [];
function getMarkerArray() {
    var key = 0;
    jQuery(".views-field-view-node").each(function() {
        var address = {};
        if(jQuery(this).find(".alternativ .plz").html() != "" && jQuery(this).find(".alternativ .ort").html() != "") {
            address.adresse = jQuery(this).find(".alternativ .strasse").html()+' '+jQuery(this).find(".alternativ .hausnummer").html()+' '+jQuery(this).find(".alternativ .plz").html()+' '+jQuery(this).find(".alternativ .ort").html();
        } else {
            address.adresse = jQuery(this).find(".persoenlich .strasse").html()+' '+jQuery(this).find(".persoenlich .hausnummer").html()+' '+jQuery(this).find(".persoenlich .plz").html()+' '+jQuery(this).find(".persoenlich .ort").html();
        }
        address.link = jQuery(this).find("a").attr("href");
        address.titel = jQuery(this).find(".gender-age").html();
        address.info = jQuery(this).find(".info").html();
        address.bild = jQuery(this).find(".image-container").html();

        address.longitude = jQuery(this).find(".info").attr("data-longitude");
        address.latitude = jQuery(this).find(".info").attr("data-latitude");

        addresses.push(address);
        createMarker(key, address.latitude, address.longitude);
        key++;
    });
    // Call that function for the first time
    //theNext();
}
// Global variable to remind us what to do next
var nextAddress = 0;
// Function to call the next Geocode operation when the reply comes back
function theNext() {
    if (nextAddress < addresses.length) {
        //setTimeout('getAddress("'+nextAddress+'", theNext)', delay);
        createMarker(nextAddress, addresses.latitude, addresses.longitude);
        nextAddress++;
    } else {
        // Show map bounds
        /*map.fitBounds(bounds);*/
        map.setZoom(9);
    }
}

var themePath = "";
function setThemePath(sPath) {
    if(typeof(sPath) != "undefined" && sPath != "") {
        themePath = sPath;
    }
}

function getUrlParameter(name) {
    var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i=0;i<vars.length;i++) {
		var pair = vars[i].split("=");
		if(pair[0] == name){return pair[1];}
	}
	return '';
}

if(typeof(Drupal) != "undefined") {
    Drupal.behaviors.myBehavior = {
        attach: function (context, settings) {
            if(jQuery("section.modul--filter").not(".filter-aktuelles").length >= 1) {
                new Foundation.Equalizer(jQuery("#filter_view_equalize")).applyHeight();

                var showMap = readCookie("karte");
                if(showMap != "" && showMap == 1 && jQuery("body").hasClass("page-ehrenaemterhtml")) {
                    jQuery(".show-map").trigger("click")
                    jQuery(".view-tiles #filter_view_equalize").fadeOut(200, function() {
                        jQuery(".view-tiles #filter_view_equalize").animate({"opacity": 1}, 200);
                        toggleMap(true);
                    });
                } else {
                    jQuery(".view-tiles #filter_view_equalize").animate({"opacity": 1}, 200);
                }

                if(jQuery(".exposed-filter-box").length) {
                    jQuery(".exposed-filter-box").appendTo(".exposed-container");
                }
                if(jQuery(".form-item-reihenfolge").length) {
                    jQuery(".form-item-reihenfolge").insertBefore(".result-count");

                    var randomHtml = '<span class="active">Neueste zuerst</span><img src="/sites/all/themes/ehrensache/images/newest_random_new.svg" /><span>Zufällig</span>';
                    //var randomHtml = '<span class="active span-newest">Neueste zuerst</span><div class="toggle-random toggle toggle-light" style="display: inline-block; vertical-align: text-top;"></div><span class="span-random">Zufällig</span>';
                    jQuery(".form-item-edit-reihenfolge-random-asc a").html(randomHtml);
                    var newHtml = '<span>Neueste zuerst</span><img src="/sites/all/themes/ehrensache/images/newest_random_rand.svg" /><span class="active">Zufällig</span>';
                    //var newHtml = '<span class="span-newest">Neueste zuerst</span><div class="toggle-random toggle toggle-light" style="display: inline-block; vertical-align: text-top;"></div><span class="active span-random">Zufällig</span>';
                    jQuery(".form-item-edit-reihenfolge-search-api-id-desc a").html(newHtml);
                    jQuery(".form-item-edit-reihenfolge-nid-desc a").html(newHtml);

                    //var randomToggle = null;
                    if(jQuery(".form-item-edit-reihenfolge-search-api-id-desc a").hasClass("active")) {
                        jQuery(".form-item-edit-reihenfolge-search-api-id-desc").hide();
                        jQuery(".form-item-edit-reihenfolge-random-asc").show();

                        /*
                        randomToggle = jQuery('.toggle-random').toggles({
                            drag: true
                        });
                        */
                    }
                    if(jQuery(".form-item-edit-reihenfolge-nid-desc a").hasClass("active")) {
                        jQuery(".form-item-edit-reihenfolge-nid-desc").hide();
                        jQuery(".form-item-edit-reihenfolge-random-asc").show();

                        /*
                        randomToggle = jQuery('.toggle-random').toggles({
                            drag: true
                        });
                        */
                    } else if(jQuery(".form-item-edit-reihenfolge-random-asc a").hasClass("active")) {
                        jQuery(".form-item-edit-reihenfolge-nid-desc").show();
                        jQuery(".form-item-edit-reihenfolge-random-asc").hide();

                        /*
                        randomToggle = jQuery('.toggle-random').toggles({
                            drag: true,
                            on: true
                        });
                        */
                    }

                    /*
                    jQuery(document).on("click", ".form-item-edit-reihenfolge-random-asc a, .form-item-edit-reihenfolge-random-desc a, .form-item-edit-reihenfolge-search-api-id-asc a", function(evt) {
                        evt.preventDefault();
                    });
                    jQuery(document).on("click", ".span-newest, .span-random", function() {
                        var $clickedToggle = jQuery(this);
                        var $parentLink = $clickedToggle.parent("a");
                        var url = $parentLink.attr("href");
                        window.location.href = url;
                    });
                    jQuery('.toggle-random').on('toggle', function(e, active) {
                        var $clickedToggle = jQuery(this);
                        var $parentLink = $clickedToggle.parent("a");
                        var url = $parentLink.attr("href");
                        window.location.href = url;
                    });
                    */
                }
                if(jQuery("body").hasClass("page-ehrenaemterhtml")) {
                    //var showHideMapLinks = '<div class="show-hide-map"><div class="show-map">Ergebnisse auf Karte anzeigen</div><img src="/sites/all/themes/ehrensache/images/icon-close-blue.svg" class="show-tiles" /></div>';
                    var showHideMapLinks = '<div class="show-hide-map"><span class="show-tiles active">Kachelliste</span><img src="/sites/all/themes/ehrensache/images/newest_random_new.svg" class="show-map-tiles-image" /><span class="show-map">Kartenansicht</span></div>';
                    jQuery(showHideMapLinks).insertBefore(".result-count");
                }

                if(typeof(google) != "undefined") {
                    infowindow = new google.maps.InfoWindow();
                    latlng = new google.maps.LatLng(51.050407, 13.737262);
                    mapOptions = {
                        zoom: 9,
                        center: latlng,
                        styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}],
                    };
                    //geo = new google.maps.Geocoder();
                    map = new google.maps.Map(document.getElementById("resultsmap"), mapOptions);
                    bounds = new google.maps.LatLngBounds();
                    getMarkerArray();
                }
            }
        }
    };
    /* Anpassung der Seitenhöhe nach Klick auf Anzeige von mehr/weniger beim Filtern - Facet API JS Funktion komplett überschrieben */
    if(Drupal.facetapi) {
        Drupal.facetapi.applyLimit = function(settings) {
            if (settings.limit > 0 && !jQuery('ul#' + settings.id).hasClass('facetapi-processed')) {
            // Only process this code once per page load.
            jQuery('ul#' + settings.id).addClass('facetapi-processed');

            // Ensures our limit is zero-based, hides facets over the limit.
            var limit = settings.limit - 1;
            jQuery('ul#' + settings.id).find('li:gt(' + limit + ')').hide();

            // Adds "Show more" / "Show fewer" links as appropriate.
            jQuery('ul#' + settings.id).filter(function() {
                return jQuery(this).find('li').length > settings.limit;
            }).each(function() {
                jQuery('<a href="#" class="facetapi-limit-link"></a>').text('... mehr laden').click(function() { /* Drupal.t(settings.showMoreText) */
                if (jQuery(this).siblings().find('li:hidden').length > 0) {
                    jQuery(this).siblings().find('li:gt(' + limit + ')').slideDown(300, function() {
                        new Foundation.Equalizer(jQuery("#filter_equalizer_global")).applyHeight();
                    });
                    jQuery(this).addClass('open').text('... weniger zeigen'); /* Drupal.t(settings.showFewerText) */
                }
                else {
                    jQuery(this).siblings().find('li:gt(' + limit + ')').slideUp(300, function() {
                        new Foundation.Equalizer(jQuery("#filter_equalizer_global")).applyHeight();
                    });
                    jQuery(this).removeClass('open').text('... mehr laden'); /* Drupal.t(settings.showMoreText) */
                }
                return false;
                }).insertAfter(jQuery(this));
            });
            }
        }
    }
}

function toggleMap(showMap) {
    if(showMap) {
        var top = jQuery(".view-header").outerHeight();
        jQuery(".view-map").css({"top": top});
        jQuery(".view-tiles #filter_view_equalize").fadeOut(200, function() {
            jQuery(".view-map").fadeIn(200);
            jQuery(".show-tiles, .show-map").toggleClass("active");
            jQuery(".show-map-tiles-image").attr("src", "/sites/all/themes/ehrensache/images/newest_random_rand.svg");
            new Foundation.Equalizer(jQuery("#filter_view_equalize")).applyHeight();
        });
        var theDate = new Date();
		var oneYearLater = new Date( theDate.getTime() + 31536000000 );
		var expiryDate = oneYearLater.toGMTString();
		document.cookie = "karte=1;expires="+expiryDate+";path=/;secure;";
    } else {
        jQuery(".view-map").fadeOut(200, function() {
            jQuery(".view-tiles #filter_view_equalize").fadeIn(200);
            jQuery(".show-tiles, .show-map").toggleClass("active");
            jQuery(".show-map-tiles-image").attr("src", "/sites/all/themes/ehrensache/images/newest_random_new.svg");
            new Foundation.Equalizer(jQuery("#filter_view_equalize")).applyHeight();
        });
        document.cookie = 'karte=0; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;secure;';
    }
}

var counted = false;
var comma_separator_number_step = jQuery.animateNumber.numberStepFactories.separator('.')
function playCounter(counterElement) {
    var valOne = counterElement.data("val");
    counterElement.animateNumber({ 
        number: valOne,
        numberStep: comma_separator_number_step
    }, 4000);
}

var windowWidth = 0;
var windowHeight = 0;
jQuery(document).ready(function() {
    jQuery(document).foundation();
    windowWidth = jQuery(window).width();
    windowHeight = jQuery(window).height();

    // Neue Funktion zum Hochzählen der Stunden-Zahl auf der Startseite
    if(jQuery('.modul--startseite-wochenstunden .text-container-wochenstunden .amount-wochenstunden').length) {
        var $collection = jQuery('.modul--startseite-wochenstunden .text-container-wochenstunden .amount-wochenstunden');
        $collection.each(function () {
            var inviewCounter = new Waypoint.Inview({
                element: this,
                enter: function(direction) {
                    // ...
                },
                entered: function(direction) {
                    var triggeredElement = jQuery(this.element);
                    setTimeout(function() { playCounter(triggeredElement); }, 500);
                },
                exit: function(direction) {
                    // ...
                },
                exited: function(direction) {
                    // ..
                }
            });
        });
    }

    // Neue Funktionen Inserat Status Seite
    jQuery(document).on("click", ".show-hide-inserat-loeschen-container", function(evt) {
        evt.preventDefault();
        jQuery(".inserat-loeschen-container").slideToggle();
    });

    // Spezielle Landkreise in der Anzeige auf Filterseiten verhindern
    if(jQuery(".facetapi-facet-field-region").length) {
      jQuery.each(jQuery(".facetapi-facet-field-region li"), function() {
        if(jQuery(this).find("a").text().includes("Vogtlandkreis 1") || jQuery(this).find("a").text().includes("Vogtlandkreis 2") || jQuery(this).find("a").text().includes("Vogtlandkreis 3")) {
          jQuery(this).remove();
        }
        if(jQuery(this).find("a").text().includes("sachsenweit") || jQuery(this).find("a").text().includes("landkreisweit")) {
            jQuery(this).hide();
          }
      });
    }

    // Tageseinsätze Filter bearbeiten
    if(jQuery("ul.facetapi-facet-field-tageseinsatz").length) {
      if(jQuery("ul.facetapi-facet-field-tageseinsatz li").length == 2) {
        jQuery.each(jQuery(".facetapi-facet-field-tageseinsatz li"), function() {
          if(jQuery(this).find("a").text().includes("Tätigkeiten")) {
            jQuery(this).remove();
          }
        });
      } else {
        if(jQuery("ul.facetapi-facet-field-tageseinsatz li a").text().includes("Tätigkeiten")) {
          jQuery("ul.facetapi-facet-field-tageseinsatz li a").parent().parent().parent().parent().parent().remove();
        }
      }
    }

    // Startseite Layer für Boxen zeigen
    jQuery(document).on("click", ".btn-show-ehrenamt-layer", function(evt) {
        evt.preventDefault();
        jQuery("body").addClass("yellow");
        openLayer("layer_auswahl_ehrenamt");
    });
    jQuery(document).on("click", ".btn-show-freiwillige-layer", function(evt) {
        evt.preventDefault();
        jQuery("body").addClass("yellow");
        openLayer("layer_auswahl_freiwillige");
    });
    jQuery(document).on("click", ".close-auswahl-layer", function() {
        jQuery.magnificPopup.close();
        jQuery("body").removeClass("yellow");
    });
    // Setzen des Links im Layer auf der Startseite je nach Auswahl des anzuzeigenden Landkreises
    jQuery(document).on("click", ".inner-freiwilligenauswahl .landkreise input[type='checkbox'], .inner-freiwilligenauswahl .landkreise-liste input[type='checkbox']", function() {
        var countChecked = 0;
        var urlParams = "";
        var cleanUrl = jQuery(".inner-freiwilligenauswahl .button").data("clean-url");
        if(jQuery(this).val() == -1 && jQuery(this).is(":checked")) {
            jQuery.each(jQuery(".inner-freiwilligenauswahl .landkreise input[type='checkbox'], .inner-freiwilligenauswahl .landkreise-liste input[type='checkbox']"), function() {
                if(jQuery(this).val() != -1) {
                    jQuery(this).prop("checked", true);
                    var lkId = jQuery(this).val();
                    var lkName = jQuery(this).data("name");
                    // Anzahl der markierten Checkboxen erhöhen
                    countChecked++;
                    // URL Parameter ergänzen
                    urlParams += "/region/"+lkName+"-"+lkId;
                }
            });
            // Alle Checkboxen markiert, also kann die Anzahl der markierten auf 1 gesetzt werden
            countChecked = 1;
            // Link-Button URL setzen
            jQuery(".inner-freiwilligenauswahl .button").attr("href", cleanUrl);
        } else {
            jQuery.each(jQuery(".inner-freiwilligenauswahl .landkreise input[type='checkbox'], .inner-freiwilligenauswahl .landkreise-liste input[type='checkbox']"), function() {
                if(jQuery(this).val() == -1) {
                    jQuery(this).prop("checked", false);
                } else {
                    if(jQuery(this).is(":checked")) {
                        var lkId = jQuery(this).val();
                        var lkName = jQuery(this).data("name");
                        // Anzahl der markierten Checkboxen erhöhen
                        countChecked++;
                        // URL Parameter ergänzen
                        urlParams += "/region/"+lkName+"-"+lkId;
                    }
                }
            });
        }
        // Link-Button URL setzen
        var btnUrl = cleanUrl + urlParams;
        jQuery(".inner-freiwilligenauswahl .button").attr("href", btnUrl);

        // Link-Button aktivieren oder deaktivieren, je nach Zahl der gewählten Landkreise
        if(countChecked > 0) {
            jQuery(".inner-freiwilligenauswahl .button").removeClass("disabled");
        } else {
            jQuery(".inner-freiwilligenauswahl .button").addClass("disabled");
        }
    });
    jQuery(document).on("click", ".inner-ehrenamtauswahl .landkreise input[type='checkbox'], .inner-ehrenamtauswahl .landkreise-liste input[type='checkbox']", function() {
        var countChecked = 0;
        var urlParams = "";
        var cleanUrl = jQuery(".inner-ehrenamtauswahl .button").data("clean-url");
        if(jQuery(this).val() == -1 && jQuery(this).is(":checked")) {
            jQuery.each(jQuery(".inner-ehrenamtauswahl .landkreise input[type='checkbox'], .inner-ehrenamtauswahl .landkreise-liste input[type='checkbox']"), function() {
                if(jQuery(this).val() != -1) {
                    jQuery(this).prop("checked", true);
                    var lkId = jQuery(this).val();
                    var lkName = jQuery(this).data("name");
                    // Anzahl der markierten Checkboxen erhöhen
                    countChecked++;
                    // URL Parameter ergänzen
                    urlParams += "/region/"+lkName+"-"+lkId;
                }
            });
            // Alle Checkboxen markiert, also kann die Anzahl der markierten auf 1 gesetzt werden
            countChecked = 1;
            // Link-Button URL setzen
            jQuery(".inner-ehrenamtauswahl .button").attr("href", cleanUrl);
        } else {
            jQuery.each(jQuery(".inner-ehrenamtauswahl .landkreise input[type='checkbox'], .inner-ehrenamtauswahl .landkreise-liste input[type='checkbox']"), function() {
                if(jQuery(this).val() == -1) {
                    jQuery(this).prop("checked", false);
                } else {
                    if(jQuery(this).is(":checked")) {
                        var lkId = jQuery(this).val();
                        var lkName = jQuery(this).data("name");
                        // Anzahl der markierten Checkboxen erhöhen
                        countChecked++;
                        // URL Parameter ergänzen
                        urlParams += "/region/"+lkName+"-"+lkId;
                    }
                }
            });
        }
        // Link-Button URL setzen
        var btnUrl = cleanUrl + urlParams;
        jQuery(".inner-ehrenamtauswahl .button").attr("href", btnUrl);

        // Link-Button aktivieren oder deaktivieren, je nach Zahl der gewählten Landkreise
        if(countChecked > 0) {
            jQuery(".inner-ehrenamtauswahl .button").removeClass("disabled");
        } else {
            jQuery(".inner-ehrenamtauswahl .button").addClass("disabled");
        }
    });

    jQuery(document).on("click", ".show-detail-map", function(e) {
        e.preventDefault();
        var target = jQuery(this).data("address");
        var name = jQuery(this).data("name");
        var longitude = jQuery(this).data("longitude");
        var latitude = jQuery(this).data("latitude");
        jQuery(".inner-detail-map .name").html(name);
        jQuery(".inner-detail-map .adresse").html(target);
        //infowindow = new google.maps.InfoWindow();
        latlng = new google.maps.LatLng(latitude, longitude); // 51.050407, 13.737262
        mapOptions = {
            zoom: 13,
            center: latlng,
            styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}],
        };
        //geo = new google.maps.Geocoder();
        map = new google.maps.Map(document.getElementById("detail_map"), mapOptions);
        bounds = new google.maps.LatLngBounds();

        var address = {};
        address.adresse = target;
        address.link = "";
        address.titel = "";
        address.info = "";
        address.bild = "";

        address.longitude = longitude;
        address.latitude = latitude;

        addresses.push(address);
        createMarker(0, address.latitude, address.longitude);
        //theNext();

        openLayer("detail_map");
    });
    jQuery(document).on("click", ".close-detail-map", function() {
        jQuery.magnificPopup.close();
    });

    jQuery(document).on("click", ".form-item-edit-reihenfolge-search-api-id-asc a", function(e) {
        e.preventDefault();
        jQuery(".views-reset-button input[type='submit']").trigger("click");
    });

    jQuery(document).on("click", ".show-map-tiles-image", function() {
        if(jQuery(".show-hide-map .show-tiles").hasClass("active")) {
            toggleMap(true);
        } else {
            toggleMap(false);
        }
    });
    jQuery(document).on("click", ".show-tiles", function() {
        toggleMap(false);
    });
    jQuery(document).on("click", ".show-map", function() {
        toggleMap(true);
    });

    if(jQuery('.ytlink').length) {
        jQuery('.ytlink').magnificPopup({
            type: 'iframe',
        iframe: {
            markup: '<div class="mfp-iframe-scaler">'+
                    '<div class="mfp-close"></div>'+
                    '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
                    '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button

            patterns: {
            youtube: {
                index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

                id: 'v=', // String that splits URL in a two parts, second part should be %id%
                // Or null - full URL will be returned
                // Or a function that should return %id%, for example:
                // id: function(url) { return 'parsed id'; }

                src: '//www.youtube.com/embed/%id%?autoplay=1' // URL that will be set as a source for iframe.
            },
            vimeo: {
                index: 'vimeo.com/',
                id: '/',
                src: '//player.vimeo.com/video/%id%?autoplay=1'
            },
            gmaps: {
                index: '//maps.google.',
                src: '%id%&output=embed'
            }

            // you may add here more sources

            },

            srcAction: 'iframe_src', // Templating object key. First part defines CSS selector, second attribute. "iframe_src" means: find "iframe" and set attribute "src".
        }
        });
    }

    if(jQuery('.galerie').length) {
        jQuery('.galerie').magnificPopup({
            delegate: 'a',
            type: 'image',
            tLoading: 'Lade Bild #%curr%...',
            mainClass: 'mfp-img-mobile',

            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0,1] // Will preload 0 - before current, and 1 after the current image
            }
        });
    }

    jQuery(".buttonmorepics").click(function(e) {
      e.preventDefault();
      jQuery(".galoverlay").fadeOut();
      jQuery(".buttonmorepics").fadeOut();
      jQuery("div.galerie .cell").fadeIn();
    });

	/* News Height */
	if(jQuery(".newselem").length) {
		jQuery('.newselem').each(function() {
		    var maxHeight = 0;
		    jQuery('.newscol', this).each(function() {
		        if(jQuery(this).height() > maxHeight) {
		         maxHeight = jQuery(this).height();
		        }
		    });
		    jQuery(".newscol").css("height", maxHeight+"px");
		});
	}
    /* Akkordeon */
    var animTime = 300, clickPolice = false;
    jQuery(document).on('touchstart click', '.acc-btn', function(){
		if(!clickPolice) {
			clickPolice = true;

			var currIndex = jQuery(this).index('.acc-btn');
			if(!jQuery(this).hasClass('selected')) {
				var targetHeight = jQuery('.acc-content-inner').eq(currIndex).outerHeight();

                jQuery(this).addClass('selected');
				jQuery('.acc-btn').not(this).removeClass('selected'); // Accordeon Effekt - Wenn geöffnete offen bleiben sollen, diese Zeile auskommentieren

				jQuery('.acc-content').stop().animate({ height: 0 }, animTime); // Accordeon Effekt - Wenn geöffnete offen bleiben sollen, diese Zeile auskommentieren
				jQuery('.acc-content').eq(currIndex).stop().animate({ height: targetHeight }, animTime);

				setTimeout(function(){ clickPolice = false; }, animTime);
			} else {
				jQuery('.acc-content').eq(currIndex).stop().animate({ height: 0 }, animTime);
				jQuery(this).removeClass('selected');
				setTimeout(function(){ clickPolice = false; }, animTime);
			}
		}
	});
	jQuery(document).on('touchstart click', '.accordeon-close', function() {
		if(!clickPolice) {
			clickPolice = true;
			jQuery(this).parents(".acc-content").stop().animate({ height: 0 }, animTime);
			jQuery(this).parents(".acc-content").prev(".acc-btn").removeClass('selected');
			setTimeout(function(){ clickPolice = false; }, animTime);
		}
	});

    /* Dropdowns schön formatieren */
    if(jQuery(".sumoselect-render").length) {
        jQuery('.sumoselect-render').SumoSelect();

        if(typeof(selectedInseratTypePreselection) != "undefined") {
            jQuery('.sumoselect-render')[0].sumo.selectItem(selectedInseratTypePreselection);
            setInseratTypeFelder(selectedInseratTypePreselection);
            new Foundation.Equalizer(jQuery("#personal_data_equalizer")).applyHeight();
        }
    }

    /* Menü öffnen/schließen */
	jQuery(".menu-button, .menu-button-mobile").click(function(e) {
        e.preventDefault();

        if(jQuery(".menu-button, .menu-button-mobile").hasClass("is-active")) {
            jQuery(".site-overlay").fadeOut(200);
        } else {
            jQuery(".site-overlay").fadeIn(200);
        }

        jQuery(".menu-button, .menu-container, .menu-button-mobile").toggleClass("is-active");
    });

    if(typeof(salvattore) != "undefined" && jQuery("#filter_equalizer_global").length) {
        window.addEventListener("resize", function() {
            setTimeout(function() {
                salvattore.rescanMediaQueries();
                setTimeout(function() {
                    new Foundation.Equalizer(jQuery("#filter_view_equalize")).applyHeight();
                    new Foundation.Equalizer(jQuery("#filter_equalizer_global")).applyHeight();
                }, 500);
            }, 200);
        }, false);
    }

    if(jQuery("#field_detail_beschreibung").length) {
      jQuery('#field_detail_beschreibung').trumbowyg({
        btns: [
            ['viewHTML'],
            ['undo', 'redo'], // Only supported in Blink browsers
            ['strong', 'em', 'del'],
            ['unorderedList', 'orderedList']
        ],
        removeformatPasted: true,
        autogrow: false,
        svgPath: "/sites/all/themes/ehrensache/js/trumbowyg/ui/icons.svg"
      });
    }

    /* Slider mit Fade-Effekt auf Startseite */
    if(jQuery(".slider-startseite").length) {
        jQuery('.slider-startseite').slick({
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: false,
			arrows: false,
			autoplay: true,
            autoplaySpeed: 5000,
            speed: 1000,
			fade: true,
            cssEase: 'ease-in-out',
            useTransform: true,
		});
    }

    /* Slider mit Fade-Effekt auf Startseite - Jubelticker */
    if(jQuery(".jubelticker-slider").length) {
        jQuery('.jubelticker-slider').slick({
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true,
			arrows: false,
			autoplay: true,
            autoplaySpeed: 12000,
            speed: 1000,
			fade: true,
            cssEase: 'ease-in-out',
            useTransform: true,
            adaptiveHeight: true,
            responsive: [
                {
                  breakpoint: 640,
                  settings: {
                    adaptiveHeight: true
                  }
                },
            ],
		});
    }
    
    /* Slider mit Fade-Effekt auf Startseite - Videos */
    if(jQuery(".drei-videos-slider").length && windowWidth <= 640) {
        jQuery('.drei-videos-slider').slick({
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true,
			arrows: false,
			autoplay: true,
            autoplaySpeed: 12000,
            speed: 1000,
			fade: true,
            cssEase: 'ease-in-out',
            useTransform: true,
            adaptiveHeight: true,
            /*
            responsive: [
                {
                  breakpoint: 640,
                  settings: {
                    slidesToShow: 1,
                    adaptiveHeight: true
                  }
                },
            ],
            */
		});
    }
    /* Slider mit Fade-Effekt auf Startseite - News-Slider */
    if(jQuery(".news-slider").length && windowWidth <= 640) {
        jQuery('.news-slider').slick({
			infinite: false,
			slidesToShow: 1.2,
			slidesToScroll: 1,
			dots: false,
			arrows: false,
			autoplay: true,
            autoplaySpeed: 12000,
            speed: 1000,
			fade: false,
            cssEase: 'ease-in-out',
            useTransform: true,
            adaptiveHeight: true
		});
    }

    /* Boxen auf Startseite vertikal positionieren */
    if(jQuery("section.modul--startseite .boxen").length) {
        setStartseiteBoxen();
        window.addEventListener('resize', function() {
            setStartseiteBoxen();
        });
    }

    /* Split-Intro Hintergründe für mobile Darstellung berechnen */
    if(jQuery("section.modul--split-intro").length) {
        reorderSplitIntroBackground();
        window.addEventListener('resize', function() {
			reorderSplitIntroBackground();
		});
    }

    /* Google Map auf Service Seite */
	if(jQuery(".modul--adresse-karte .map").length) {
		var lat = 51.053890;
		var long = 13.803690;
		var config = {
			zoom: 14,
            center: new google.maps.LatLng(lat, long),
            styles: [ { "featureType": "water", "elementType": "geometry", "stylers": [ { "color": "#e9e9e9" }, { "lightness": 17 } ] }, { "featureType": "landscape", "elementType": "geometry", "stylers": [ { "color": "#f5f5f5" }, { "lightness": 20 } ] }, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [ { "color": "#ffffff" }, { "lightness": 17 } ] }, { "featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [ { "color": "#ffffff" }, { "lightness": 29 }, { "weight": 0.2 } ] }, { "featureType": "road.arterial", "elementType": "geometry", "stylers": [ { "color": "#ffffff" }, { "lightness": 18 } ] }, { "featureType": "road.local", "elementType": "geometry", "stylers": [ { "color": "#ffffff" }, { "lightness": 16 } ] }, { "featureType": "poi", "elementType": "geometry", "stylers": [ { "color": "#f5f5f5" }, { "lightness": 21 } ] }, { "featureType": "poi.park", "elementType": "geometry", "stylers": [ { "color": "#dedede" }, { "lightness": 21 } ] }, { "elementType": "labels.text.stroke", "stylers": [ { "visibility": "on" }, { "color": "#ffffff" }, { "lightness": 16 } ] }, { "elementType": "labels.text.fill", "stylers": [ { "saturation": 36 }, { "color": "#333333" }, { "lightness": 40 } ] }, { "elementType": "labels.icon", "stylers": [ { "visibility": "off" } ] }, { "featureType": "transit", "elementType": "geometry", "stylers": [ { "color": "#f2f2f2" }, { "lightness": 19 } ] }, { "featureType": "administrative", "elementType": "geometry.fill", "stylers": [ { "color": "#fefefe" }, { "lightness": 20 } ] }, { "featureType": "administrative", "elementType": "geometry.stroke", "stylers": [ { "color": "#fefefe" }, { "lightness": 17 }, { "weight": 1.2 } ] } ],
		};
		window.addEventListener('load', function() {
			generateMap(lat, long, config);
		});
    }

    /* Höhe von Download-Icons berechnen */
    if(jQuery(".download-icon").length) {
        calculateDownloadIcons();
        window.addEventListener('resize', function() {
            calculateDownloadIcons();
        });
    }

    /* Filterliste Anpassung der Höhen für Bilder */
    if(jQuery("section.modul--filter").length) {
        /*
        var fotoImages = document.getElementsByClassName("foto");
        var fotoImagesCount = fotoImages.length;
        var completeLoadedFotoImages = 0;
        while(completeLoadedFotoImages < fotoImagesCount) {
            for(var i = 0; i < fotoImagesCount; i++) {
                if(fotoImages[i].complete) {
                    completeLoadedFotoImages += 1;
                }
            }
        }
        if(completeLoadedFotoImages == fotoImagesCount) {
            calculateFilterListeImageHeight();
            new Foundation.Equalizer(jQuery("#global_equalizer")).applyHeight();
        }
        */

        setTimeout(function() {
            //new Foundation.Equalizer(jQuery("#filter_view_equalize")).applyHeight();
            calculateFilterListeImageHeight();
        }, 500);
        window.addEventListener('resize', function() {
            calculateFilterListeImageHeight();
        });
    }
    /* Filter öffnen/schließen bei mobiler Darstellung */
    jQuery(document).on("click", ".show-hide-filter", function() {
        jQuery(this).toggleClass("open");
        jQuery(".region-sidebar-second").slideToggle(300);
    });
    /* PLZ Filter Funktionen */
    jQuery(document).on("keyup", "#facet-field-pd-plz", function(evt) {
        if ((evt.keyCode >= 48 && evt.keyCode <= 57) || (evt.keyCode >= 65 && evt.keyCode <= 90) || evt.keyCode == 8 || evt.keyCode == 46) {
            var typedInput = jQuery(this).val();
            if(typedInput.length > 0) {
                jQuery(".facetapi-facet-field-pd-plz").show();
                /*
                jQuery.each(jQuery(".facetapi-facet-field-pd-plz li"), function() {
                    var linkObject = jQuery(this).children("a");
                    linkObject.children("span").remove();
                    var innerText = linkObject.text().trim();
                    //if(innerText.includes(typedInput)) {
                    if(innerText.indexOf(typedInput) > -1) {
                        jQuery(".facetapi-facet-field-pd-plz").show();
                        return;
                    } else {
                        jQuery(".facetapi-facet-field-pd-plz").hide();
                        return;
                    }
                });
                */
            } else {
                jQuery(".facetapi-facet-field-pd-plz").hide();
            }
            new Foundation.Equalizer(jQuery("#filter_view_equalize")).applyHeight();
        }
    });
    if(jQuery(".facetapi-facet-field-pd-plz")) {
        jQuery.each(jQuery(".facetapi-facetapi-checkbox-links li"), function() {
            if(jQuery(this).children(".facetapi-checkbox").is(":checked")) {
                jQuery(".facetapi-facet-field-pd-plz").show();
            }
        });
    }
    /* Aktive Filter oberhalb der Ergebnisliste anzeigen */
    jQuery.each(jQuery(".item-list ul"), function() {
        var showFilterMobile = false;
        jQuery.each(jQuery(this).children("li"), function() {
            if(jQuery(this).children("a").hasClass("facetapi-active")) {
                jQuery(".active-filter-container").show();
                var activeId = jQuery(this).children("input[type='checkbox']").attr("id");
                var linkObject = jQuery(this).children("a");
                var labelObject = jQuery(this).children("label");
                linkObject.remove();
                labelObject.remove();
                var innerText = jQuery(this).text().trim();
                if(jQuery(this).parents("ul").hasClass("facetapi-facet-field-pd-plz")) {
                    innerText = "PLZ: "+innerText;
                }
                jQuery(".active-filter-buttons").append('<div class="active-filter">'+innerText+'<img src="'+themePath+'/images/icon-close-blue.svg" class="remove-filter" data-filter="'+activeId+'"></div>');
                showFilterMobile = true;
            }
        });

        /*
        if(showFilterMobile && windowWidth <= 640) {
            jQuery(".show-hide-filter").trigger("click");
        }
        */
    });
    /* Filter entfernen */
    jQuery(document).on("click", ".remove-filter", function() {
        var target = jQuery(this).data("filter");
        jQuery("#"+target).trigger("click");
    });

    /* Zum Kontaktformular scrollen */
    jQuery(document).on("click", ".scroll-to-form", function() {
        jQuery('html, body').animate({
            scrollTop: (jQuery("#formular").offset().top)
        }, 500);
    });

    /* Typ des Inserats wechseln (Ehrenamt, Freiwilliger) */
    jQuery(document).on("change", "#inserieren_type", function() {
        setInseratTypeFelder(jQuery(this).val());
    });

    /* Art des Inserats ändern */
    jQuery(document).on("change", "#inserieren_art", function() {
        setInseratArt(jQuery(this).val());
    });
    /* Auswahl zwischen Tages- oder Mehrtageseinsatz */
    jQuery(document).on("click", "input[name='einsatzart']", function() {
        if(jQuery(this).val() == "single") { // Tageseinsatz
          jQuery(".einsatzart-mehrtageseinsatz").hide();
          jQuery(".einsatzart-tageseinsatz").show();
        } else { // Mehrtageseinsatz
          jQuery(".einsatzart-tageseinsatz").hide();
          jQuery(".einsatzart-mehrtageseinsatz").show();
        }
    });
    /* "Nach Absprache" klicken und Zeitaufwand Feld ausblenden */
    jQuery(document).on("click", "input[name='field_zeitaufwand_intervall']", function() {
        if(jQuery(this).is(":checked") && jQuery(this).val() == "3") {
            jQuery("input#field_detail_zeitaufwand").hide();
        } else {
            jQuery("input#field_detail_zeitaufwand").show();
        }
	});
    jQuery(document).on("click", "input[name='field_zeitaufwand_intervall_einmalig']", function() {
        if(jQuery(this).is(":checked") && jQuery(this).val() == "3") {
            jQuery("input#field_detail_zeitaufwand").hide();
        } else {
            jQuery("input#field_detail_zeitaufwand").show();
        }
	});

    /* Alternative Adresse anzeigen */
    jQuery(document).on("click", "#check_abweichende_adresse", function() {
        var status = jQuery(this).is(":checked");
        if(status) {
            jQuery(".alternative-address").show();
            jQuery("input#field_alt_plz, input#field_alt_ort, input#field_alt_strasse, input#field_alt_hausnummer").prop('required', true);
        } else {
            jQuery(".alternative-address").hide();
            jQuery("input#field_alt_plz, input#field_alt_ort, input#field_alt_strasse, input#field_alt_hausnummer").prop('required', false);
        }
    });

    /* File-Upload Foto (Profilbild) initialisieren */
    if(jQuery("#fileupload_profilbild").length) {
      jQuery('#fileupload_profilbild').fileupload({
        add: function(e, data) {
          if(e.target.id == "fileupload_profilbild") {
            var uploadErrors = [];
            var acceptFileTypes = /^image\/(gif|jpe?g|png)$/i;
            console.log(data);
            //console.log(e);
            if(data.originalFiles[0]['type'].length && !acceptFileTypes.test(data.originalFiles[0]['type'])) {
                uploadErrors.push('Dateityp nicht erlaubt!');
            }
            /*
            if(data.originalFiles[0]['size'].length && data.originalFiles[0]['size'] > 5000000) {
                uploadErrors.push('Filesize is too big');
            }
            */
            if(uploadErrors.length > 0) {
                alert(uploadErrors.join("\n"));
            } else {
                data.submit();
            }
          }
        },
        singleFileUploads: true,
        dataType: 'json',
        dropZone: "foto_dropzone",
        maxNumberOfFiles: 1,
        //acceptFileTypes: /(\.|\/)(jpe?g|png|gif)$/i,
        /*change : function (e, data) {
            if(data.files.length >= 2){
                alert("Maximal eine Datei erlaubt.");
                return false;
            }
        },*/
        done: function (e, data) {
            jQuery.each(data.result.files, function (index, file) {
                if(typeof(file.error) != "undefined" && file.error != "") {
                    alert(file.error);
                } else {
                    var appendHtml = jQuery('<img src="/sites/all/themes/ehrensache/inserat_upload/files/thumbnail/'+file.name+'" />'+
                                            '<input type="hidden" name="upload_foto_filename" id="upload_foto_filename" value="'+file.name+'" />'+
                                            '<button id="del_btn_foto" class="btn btn-delete" data-type="'+file.deleteType+'" data-url="'+file.deleteUrl+'"></button>');
                    jQuery('#upload_foto').append(appendHtml).fadeIn();

                    jQuery(document).on('click', '#del_btn_foto', function () {
                        var $link = jQuery(this);

                        if(confirm("Soll die Datei wirklich gelöscht werden?\rDiese Aktion kann nicht rückgängig gemacht werden.")) {
                            var req = jQuery.ajax({
                                dataType: 'json',
                                url: $link.data('url'),
                                type: 'DELETE',
                            });

                            req.success(function () {
                                jQuery('#upload_foto').fadeOut("fast", function() {
                                    jQuery('#upload_foto').html("");
                                });
                            });
                        }
                    });
                }
            });
        },
        fail: function (e, data) {
            alert(data.errorThrown);
            // data.textStatus;
            // data.jqXHR;
        },
      });
    }
    /* Größe des Foto-Upload Buttons nach Auflösung anpassen */
    if(jQuery(".file-foto").length) {
        updateFotoButtonWidth();

        window.addEventListener('resize', function() {
            updateFotoButtonWidth();
        });
    }

    /* File-Upload PDF Dateien initialisieren */
    if(jQuery("#fileupload_pdf").length) {
      jQuery('#fileupload_pdf').fileupload({
        add: function(e, data) {
          if(e.target.id == "fileupload_pdf") {
            var uploadErrors = [];
            var acceptFileTypes = /\/(pdf)$/i;
            console.log(data);
            //console.log(e);
            if(data.originalFiles[0]['type'].length && !acceptFileTypes.test(data.originalFiles[0]['type'])) {
                uploadErrors.push('Dateityp nicht erlaubt!');
            }
            if(data.originalFiles[0]['size'].length && data.originalFiles[0]['size'] > 5000000) {
                uploadErrors.push('Datei ist zu groß!');
            }
            if(uploadErrors.length > 0) {
                alert(uploadErrors.join("\n"));
            } else {
                data.submit();
            }
          }
        },
        singleFileUploads: false,
        limitMultiFileUploads: 3,
        maxNumberOfFiles: 3,
        dataType: 'json',
        dropZone: "pdf_dropzone",
        //acceptFileTypes: /(\.|\/)(pdf)$/i,
        /*change : function (e, data) {
            if(data.files.length >= 4){
                alert("Maximal 3 Dateien erlaubt.");
                return false;
            }
        },*/
        done: function (e, data) {
            jQuery.each(data.result.files, function (index, file) {
                if(typeof(file.error) != "undefined" && file.error != "") {
                    alert(file.error);
                } else {
                    console.log(file);
                    var containerId = uniqueId();
                    var appendHtml = jQuery('<span data-url="'+file.name+'" id="upload_pdf_'+containerId+'" data-id="'+containerId+'" class="pdf-item">'+
                                            '	<span>'+file.name+'</span>'+
                                            '	<input type="hidden" name="upload_foto_filename_'+containerId+'" id="upload_foto_filename_'+containerId+'" value="'+file.name+'" />'+
                                            '   <input type="text" name="upload_foto_filename_name_'+containerId+'" id="upload_foto_filename_name_'+containerId+'" value="" placeholder="opt. Bezeichnung der Datei">'+
                                            '	<button id="del_btn_foto_'+containerId+'" class="btn btn-delete-pdf" data-type="'+file.deleteType+'" data-url="'+file.deleteUrl+'"></button>'+
                                            '</span>');
                    jQuery('.pdf-uploaded-container').append(appendHtml);

                    jQuery(document).on('click', '#del_btn_foto_'+containerId, function () {
                        var $link = jQuery(this);

                        if(confirm("Soll die Datei wirklich gelöscht werden?\rDiese Aktion kann nicht rückgängig gemacht werden.")) {
                            var req = jQuery.ajax({
                                dataType: 'json',
                                url: $link.data('url'),
                                type: 'DELETE',
                            });

                            req.success(function () {
                                jQuery('#upload_pdf_'+containerId).fadeOut("fast", function() {
                                    jQuery('#upload_pdf_'+containerId).remove();
                                });
                            });
                        }
                    });
                }
            });
        },
        fail: function (e, data) {
            alert(data.errorThrown);
            // data.textStatus;
            // data.jqXHR;
        },
      });
    }
    /* Größe des PDF-Upload Buttons nach Auflösung anpassen */
    if(jQuery(".file-pdf").length) {
        updatePdfButtonWidth();

        window.addEventListener('resize', function() {
            updatePdfButtonWidth();
        });
    }

    /* Popup Layer schließen */
    jQuery(document).on("click", ".popup-close", function() {
        var magnificPopup = jQuery.magnificPopup.instan
        magnificPopup.close();
    });

    /* Inserat abschicken */
    jQuery(document).on("click", ".submit-terminanfrage", function() {
      if(jQuery(".form--termin")[0].checkValidity()) {
        var formdata = {};

        if(jQuery("#termin_name").length) {
          formdata.termin_name = jQuery("#termin_name").val();
        }
        if(jQuery("#termin_vorname").length) {
          formdata.termin_vorname = jQuery("#termin_vorname").val();
        }
        if(jQuery("#termin_instrument").length) {
          formdata.termin_instrument = jQuery("#termin_instrument").val();
        }
        if(jQuery("#termin_email").length) {
          formdata.termin_email = jQuery("#termin_email").val();
        }
        if(jQuery("#termin_telefon").length) {
          formdata.termin_telefon = jQuery("#termin_telefon").val();
        }
        if(jQuery("#termin_soloduo").length) {
          formdata.termin_soloduo = jQuery("#termin_soloduo").val();
        }
        if(jQuery("#termin_nachricht").length) {
          formdata.termin_nachricht = jQuery("#termin_nachricht").val();
        }

        formdata.termin_daten  = jQuery("#termin_daten").val();
        formdata.termin_nid    = jQuery("#termin_nid").val();

        jQuery.ajax({
          type: "POST",
          url: "/func-sendtermin.php",
          data: formdata,
          dataType: 'json',
          cache: false,
          beforeSend: function() {
            jQuery(".submit-terminanfrage").fadeOut("fast");
          },
          success: function(data) {
            jQuery(".submit-terminanfrage-success").fadeIn("fast");
          },
          error: function(jqXHR,textStatus,errorThrown) {
            console.log(jqXHR);
            console.log(textStatus);
            console.log(errorThrown);
            alert("FEHLER");
          }
        });
      } else {
        jQuery(".form--termin")[0].reportValidity();
      }
    });

    /* Inserat abschicken */
    jQuery(document).on("click", ".submit-inserat", function() {
		if(jQuery(".form-inserat")[0].checkValidity()) {
			var formdata = {};

			if(jQuery("#inserieren_type").length) {
				formdata.form =  jQuery("#inserieren_type").val();
			}

			if(jQuery("#field_detail_titel").length) {
				formdata.titel = jQuery("#field_detail_titel").val();
			}
			if(jQuery("#field_detail_beschreibung").length) {
				formdata.beschreibung = jQuery("#field_detail_beschreibung").val();
			}
            if(jQuery("input[name='field_zeitaufwand_intervall']").length) {
				formdata.zeitaufwand_intervall =  jQuery("input[name='field_zeitaufwand_intervall']:checked").val();
			}
            //if(jQuery("#field_zeitaufwand_select").length) {
			//	formdata.zeitaufwand_select =  jQuery("#field_zeitaufwand_select").val();
			//}
			if(jQuery("#field_detail_zeitaufwand").length) {
				formdata.zeitaufwand =  jQuery("#field_detail_zeitaufwand").val();
            }
            if(jQuery("#field_barrierefreiheit").length) {
				formdata.barrierefreiheit = jQuery("#field_barrierefreiheit").is(":checked") ? 1 : 0;
			}
			if(jQuery("#field_pd_name").length) {
				formdata.name = jQuery("#field_pd_name").val();
			}
			if(jQuery("#field_pd_institution").length) {
				formdata.institution = jQuery("#field_pd_institution").val();
			}
			if(jQuery("#field_pd_ansprechpartner").length) {
				formdata.ansprechpartner = jQuery("#field_pd_ansprechpartner").val();
			}
			if(jQuery("#field_pd_email").length) {
				formdata.email = jQuery("#field_pd_email").val();
			}
			if(jQuery("#field_pd_telefon").length) {
				formdata.telefon = jQuery("#field_pd_telefon").val();
			}
			if(jQuery("#field_pd_url").length) {
				formdata.url = jQuery("#field_pd_url").val();
			}
			if(jQuery("#field_pd_ort").length) {
				formdata.ort = jQuery("#field_pd_ort").val();
			}
			if(jQuery("#field_pd_strasse").length) {
				formdata.strasse = jQuery("#field_pd_strasse").val();
			}
			if(jQuery("#field_pd_hausnummer").length) {
				formdata.hausnummer = jQuery("#field_pd_hausnummer").val();
			}
			if(jQuery("#field_alt_plz").length) {
				formdata.alt_plz = jQuery("#field_alt_plz").val();
			}
			if(jQuery("#field_alt_ort").length) {
				formdata.alt_ort = jQuery("#field_alt_ort").val();
			}
			if(jQuery("#field_alt_strasse").length) {
				formdata.alt_strasse = jQuery("#field_alt_strasse").val();
			}
			if(jQuery("#field_alt_hausnummer").length) {
				formdata.alt_hausnummer = jQuery("#field_alt_hausnummer").val();
			}
			if(jQuery("#field_pd_alter").length) {
				formdata.alter = jQuery("#field_pd_alter").val();
			}
			if(jQuery("#field_pd_alter_exakt").length) {
				formdata.alterexakt = jQuery("#field_pd_alter_exakt").val();
			}

			if(jQuery("input[name='field_pd_geschlecht']").length) {
				formdata.geschlecht =  jQuery("input[name='field_pd_geschlecht']:checked").val();
			}

            // Besondere Angaben aus dem Formular für Freiwillige
			if(formdata.form == "freiwilliger") {
                formdata.inserat_art = "";
                if(jQuery("input[name='field_detail_gueltigkeitsdauer']").length) {
                    formdata.gueltigkeit =  jQuery("input[name='field_detail_gueltigkeitsdauer']:checked").val();
                }

				if(jQuery("#field_pd_plz").length) {
					formdata.plz = jQuery("#field_pd_plz").val();
				}
			}
            // Besondere Angaben aus dem Formular für Ehrenämter
            else {
                if(jQuery("#field_pd_plz_institution").length) {
                    formdata.plz = jQuery("#field_pd_plz_institution").val();
                }

                // NEU Aktionstage
                jQuery(".sumo_inserieren_art p.SelectBox").css({"border": "1px solid rgba(16,76,98,0.26)"});
                formdata.gueltigkeit = "";
                formdata.inserat_art = jQuery("#inserieren_art").val();
                if(jQuery("#inserieren_art").val() == "fortlaufend") {
                    if(jQuery("input[name='field_detail_gueltigkeitsdauer']").length) {
                        formdata.gueltigkeit = jQuery("input[name='field_detail_gueltigkeitsdauer']:checked").val();
                    }
                } else if(jQuery("#inserieren_art").val() == "einmalig") {
                    formdata.zeitaufwand_intervall = jQuery("input[name='field_zeitaufwand_intervall_einmalig']:checked").val();

                    formdata.einsatzart = jQuery("input[name='einsatzart']:checked").val();
                    // Tageseinsatz
                    if(jQuery("input[name='einsatzart']:checked").val() == "single") {
                        formdata.tageseinsatz_datum = "";
                        if(jQuery("input[name='field_datum_start']").length) {
                        formdata.tageseinsatz_datum = jQuery("input[name='field_tageseinsatz_datum']").val();
                        }
                    }
                    // Mehrtageseinsatz
                    else {
                        formdata.mehrtageseinsatz_datum_start = "";
                        if(jQuery("input[name='field_datum_start']").length) {
                        formdata.mehrtageseinsatz_datum_start = jQuery("input[name='field_datum_start']").val();
                        }
                        formdata.mehrtageseinsatz_datum_ende = "";
                        if(jQuery("input[name='field_datum_ende']").length) {
                        formdata.mehrtageseinsatz_datum_ende = jQuery("input[name='field_datum_ende']").val();
                        }
                    }
                } else {
                    jQuery(".sumo_inserieren_art p.SelectBox").css({"border": "1px solid red"});
                    jQuery("html").animate({
                            scrollTop: jQuery("#inserieren_art").offset().top
                        },
                        500
                    );

                    return false;
                }
            }
			
            if(jQuery("#upload_foto_filename").length) {
                formdata.profilbild = jQuery("#upload_foto_filename").val();
            }
            if(jQuery(".pdf-uploaded-container .pdf-item").length) {
                var pdfFiles = [];
                var counter = 0;
                jQuery.each(jQuery(".pdf-uploaded-container .pdf-item"), function() {
                    var id = jQuery(this).data("id");
                    var filename = jQuery(this).data("url");
                    var bezeichnung = jQuery(this).find("input#upload_foto_filename_name_"+id).val();
                    pdfFiles[counter] = filename+"-|-"+bezeichnung;
                    counter++;
                });
                formdata.pdf = Object.assign({}, pdfFiles);
            }
            if(jQuery(".check-handlungsfelder").length) {
                var handlungsfelderTids = [];
                var counter = 0;
                jQuery.each(jQuery(".check-handlungsfelder"), function() {
                    if(jQuery(this).is(":checked")) {
                        var handlungsfeldTid = jQuery(this).val();
                        handlungsfelderTids[counter] = handlungsfeldTid;
                        counter++;
                    }
                });
                formdata.handlungsfelder = Object.assign({}, handlungsfelderTids);
            }
            if(jQuery(".check-sprachkenntnisse").length) {
                var sprachkenntnisseTids = [];
                var counter = 0;
                jQuery.each(jQuery(".check-sprachkenntnisse"), function() {
                    if(jQuery(this).is(":checked")) {
                        var sprachkenntnisTid = jQuery(this).val();
                        sprachkenntnisseTids[counter] = sprachkenntnisTid;
                        counter++;
                    }
                });
                formdata.sprachkenntnisse = Object.assign({}, sprachkenntnisseTids);
            }
            if(jQuery(".check-alter").length) {
                var alterTids = [];
                var counter = 0;
                jQuery.each(jQuery(".check-alter"), function() {
                    if(jQuery(this).is(":checked")) {
                        var alterTid = jQuery(this).val();
                        alterTids[counter] = alterTid;
                        counter++;
                    }
                });
                formdata.erforderlichesalter = Object.assign({}, alterTids);
            }

            jQuery.ajax({
				type: "POST",
				url: "/func-addnode.php",
				data: formdata,
				dataType: 'json',
                cache: false,
                beforeSend: function() {
                    jQuery(".submit-inserat").fadeOut("fast");
                },
				success: function(data) {
					if(formdata.form == "institution") {
                        jQuery(".find-region-offers-link").attr("href", data.region_inserate_link);
                        openLayer("inserat_institution");
                        gtag('event', 'Insert Institution angelegt', {
                        'event_category': 'Inserat Institution angelegt',
                        'event_label': 'Inserat Institution angelegt'
                        });
                        /*console.log("GT: IN")*/
				    } else {
                        jQuery(".find-region-offers-link").attr("href", data.region_inserate_link);
						openLayer("inserat_freiwilliger");
                        gtag('event', 'Insert Freiwilliger angelegt', {
                        'event_category': 'Inserat Freiwilliger angelegt',
                        'event_label': 'Inserat Freiwilliger angelegt'
                        });
                        /*console.log("GT: F")*/
                    }
                    jQuery(".submit-inserat").fadeIn("fast");
				},
				error: function(jqXHR,textStatus,errorThrown) {
					console.log(jqXHR);
					console.log(textStatus);
					console.log(errorThrown);
                    alert("ERROR");
                    jQuery(".submit-inserat").fadeIn("fast");
				}
			});
		} else {
			jQuery(".form-inserat")[0].reportValidity();
		}
    });

    /* Social Icons Dropdown in mobiler Ansicht */
    jQuery(document).on("click", ".social-flag-mobile a", function(e) {
        e.preventDefault();

        if(jQuery(".social-icons-mobile").is(":visible")) {
            jQuery(".social-icons-mobile").slideUp("fast");
        } else {
            jQuery(".social-icons-mobile").slideDown("fast");
        }
    });

    /* Anfrageformulare */
    jQuery(document).ready(function() {
        jQuery(document).on("click", ".submit-anfrage", function() {
            if(jQuery(".form")[0].checkValidity()) {
                var formdata = {};

                if(jQuery("#anfrage_name").length) {
                    formdata.name = jQuery("#anfrage_name").val();
                }
                if(jQuery("#anfrage_institution").length) {
                    formdata.institution = jQuery("#anfrage_institution").val();
                }
                if(jQuery("#anfrage_email").length) {
                    formdata.email =  jQuery("#anfrage_email").val();
                }
                if(jQuery("#anfrage_plz").length) {
                    formdata.plz =  jQuery("#anfrage_plz").val();
                }
                if(jQuery("#anfrage_nachricht").length) {
                    formdata.nachricht = jQuery("#anfrage_nachricht").val();
                }
                if(jQuery("#kontakt_telefon").length) {
                    formdata.telefonnummer = jQuery("#kontakt_telefon").val();
                }
                if(jQuery("#anfrage_telefon").length) {
                    formdata.telefonnummer = jQuery("#anfrage_telefon").val();
                }
                if(jQuery("#kontakt_tag").length) {
                    formdata.termin_tag = jQuery("#kontakt_tag").val();
                }
                if(jQuery("#anfrage_monat").length) {
                    formdata.termin_monat = jQuery("#anfrage_monat").val();
                }
                if(jQuery("#kontakt_per_telefon").length) {
                    formdata.kontakt_per_telefon = jQuery("#kontakt_per_telefon").is(":checked") ? 1 : 0;
                }
                if(jQuery("#form").length) {
                    formdata.form =  jQuery("#form").val();
                }
                if(jQuery("#nid").length) {
                    formdata.nid =  jQuery("#nid").val();
                }

                jQuery.ajax({
                    type: "POST",
                    url: themePath+"/templates/func-sendmails.php",
                    data: formdata,
                    dataType: 'json',
                    cache: false,
                    success: function(data) {
                        if(formdata.form == "freiwilliger") {
                            openLayer("anfrage_freiwilliger");
              							gtag('event', 'Kontaktanfrage Freiwilliger', {
              							  'event_category': 'Kontaktanfrage Freiwilliger',
              							  'event_label': 'Kontaktanfrage Freiwilliger'
              							});
                        } else if(formdata.form == "ehrenamt") {
                            openLayer("anfrage_institution");
              							gtag('event', 'Kontaktanfrage Institution', {
              							  'event_category': 'Kontaktanfrage Institution',
              							  'event_label': 'Kontaktanfrage Institution'
              							});
                        } else if(formdata.form == "beratungstermin") {
                            openLayer("anfrage_kontakt");
              							gtag('event', 'Kontaktanfrage Beratungstermin', {
              							  'event_category': 'Kontaktanfrage Beratungstermin',
              							  'event_label': 'Kontaktanfrage Beratungstermin'
              							});
                        }
                        //alert("SUCCESS - LAYER ANZEIGEN ");
                    },
                    error: function(jqXHR,textStatus,errorThrown) {
                        console.log(jqXHR);
                        console.log(textStatus);
                        console.log(errorThrown);
                        alert("ERROR");
                    }
                });
            }
            else {
                jQuery(".form")[0].reportValidity();
            }
        });
    });

    /* Verlängern eines Inserats */
    jQuery(document).on("click", ".send-inserat-verlaengern-email", function(event) {
		event.preventDefault();
		var $clickedElement = jQuery(this);
		jQuery(".error-message, .success-message").fadeOut().html("");

		var id = $clickedElement.data("id");
		var key = $clickedElement.data("key");

		if(id === "" || key === "") {
			jQuery(".error-message").html("Zur Verlängerung des Inserats fehlen benötigte Daten.").fadeIn();
			return;
		}

        var submitData = {
            "id": id,
            "key": key,
        };

        jQuery.ajax({
            type: "POST",
            url: themePath+"/templates/submit-inserat-verlaengern.php",
            data: submitData,
            cache: false,
            dataType: 'json',
            beforeSend: function() {
                $clickedElement.addClass("inactive");
            },
            success: function(data) {
                if(data.status) {
                    jQuery(".success-message").html('<p>Ihre Anfrage zur Verlängerung des Inserats wurde erfolgreich versendet.</p>').fadeIn();
                } else {
                    $clickedElement.removeClass("inactive");
                    jQuery(".error-message").html('<p>Ihre Anfrage zur Verlängerung des Inserats konnte nicht versendet werden.</p>').fadeIn();
                }
            },
            error: function() {
                $clickedElement.removeClass("inactive");
                jQuery(".error-message").html('<p>Ihre Anfrage zur Verlängerung des Inserats konnte nicht versendet werden.</p>').fadeIn();
            }
        });
	});

    /* Löschen eines Inserats */
    jQuery(document).on("click", ".submit-inserat-loeschen", function(event) {
		event.preventDefault();
		var status = true;
		var $clickedElement = jQuery(this);
		jQuery(".error-message").fadeOut().html("");

		var id = $clickedElement.data("id");
		var key = $clickedElement.data("key");
		var loeschbestaetigung = jQuery("#inserat_loeschen").is(":checked") ? 1 : 0;

		if(id === "" || key === "") {
			jQuery(".error-message").html("Zur Löschung des Inserats fehlen benötigte Daten.").fadeIn();
			return;
		}
		if(loeschbestaetigung != 1) {
			status = false;
			jQuery("label[for='inserat_loeschen'] span.label").addClass("error");
		}

		if(status) {
			var submitData = {
				"id": id,
				"key": key,
				"loeschbestaetigung": loeschbestaetigung,
			};

			jQuery.ajax({
				type: "POST",
				url: themePath+"/templates/submit-inserat-remove.php",
				data: submitData,
				cache: false,
				dataType: 'json',
				beforeSend: function() {
                    $clickedElement.fadeOut("fast");
                    jQuery("label[for='inserat_loeschen']").fadeOut("fast");
				},
				success: function(data) {
					if(data.status) {
						jQuery(".success-message").html('<p>Ihr Inserat wurde erfolgreich gelöscht.</p>').fadeIn();
					} else {
                        $clickedElement.fadeIn("fast");
                        jQuery("label[for='inserat_loeschen']").fadeIn("fast");
						jQuery(".error-message").html('<p>Ihr Inserat konnte nicht gelöscht werden.</p>').fadeIn();
					}
				},
				error: function() {
                    $clickedElement.fadeIn("fast");
                    jQuery("label[for='inserat_loeschen']").fadeIn("fast");
					jQuery(".error-message").html('<p>Ihr Inserat konnte nicht gelöscht werden.</p>').fadeIn();
				}
			});
		} else {
			jQuery(".error-message").html("<p>Bitte bestätigen Sie die Löschung des Inserats.</p>").fadeIn();
		}
	});

  /* Feedback abschicken */
    jQuery(document).on("click", ".submit-feedback", function() {
		if(jQuery(".form-feedback")[0].checkValidity()) {
			var formdata = {};

      if(jQuery("#type").length) { // Institut, Freiwilliger
				formdata.type =  jQuery("#type").val();
			}
			if(jQuery("#hash").length) { // Institut, Freiwilliger
				formdata.hash =  jQuery("#hash").val();
			}
      if(jQuery("#field_gewonnene_freiwillige").length) { // Institut
				formdata.gewonnene_freiwillige = jQuery("#field_gewonnene_freiwillige").val();
			}
      if(jQuery("#field_ehrenamt_gefunden_ja").length) { // Freiwilliger
				formdata.gefunden = jQuery("#field_ehrenamt_gefunden_ja").is(":checked") ? 1 : 0;
			}
			if(jQuery("#field_stunden_pro_woche").length) { // Institut, Freiwilliger
				formdata.wochenstunden = jQuery("#field_stunden_pro_woche").val();
			}
			if(jQuery("#field_aktive_organisation").length) { // Freiwilliger
				formdata.organisation = jQuery("#field_aktive_organisation").val();
			}
			if(jQuery("input[name='field_handlungsfelder']").length) { // Institut, Freiwilliger
				formdata.handlungsfelder =  jQuery("input[name='field_handlungsfelder']:checked").val();
      }
      if(jQuery("#field_alter").length) { // Freiwilliger
				formdata.alter =  jQuery("#field_alter").val();
      }
      if(jQuery("input[name='field_zufriedenheit']").length) { // Institut, Freiwilliger
				formdata.zufriedenheit =  jQuery("input[name='field_zufriedenheit']:checked").val();
      }
			if(jQuery("#field_nachricht").length) { // Institut, Freiwilliger
				formdata.nachricht = jQuery("#field_nachricht").val();
			}

			jQuery.ajax({
				type: "POST",
				url: "/func-feedback.php",
				data: formdata,
				dataType: 'json',
                cache: false,
                beforeSend: function() {
                    jQuery(".submit-feedback").fadeOut("fast");
                },
				success: function(data) {
					if(formdata.type == "institution") {
						openLayer("feedback_institution");
            gtag('event', 'Feedback Institution uebermittelt', {
            'event_category': 'Feedback Institution uebermittelt',
            'event_label': 'Feedback Institution uebermittelt'
            });
				  } else {
						openLayer("feedback_freiwilliger");
            gtag('event', 'Feedback Freiwilliger uebermittelt', {
            'event_category': 'Feedback Freiwilliger uebermittelt',
            'event_label': 'Feedback Freiwilliger uebermittelt'
            });
          }
          jQuery(".submit-feedback").fadeIn("fast");
				},
				error: function(jqXHR,textStatus,errorThrown) {
					console.log(jqXHR);
					console.log(textStatus);
					console.log(errorThrown);
          alert("ERROR");
          jQuery(".submit-feedback").fadeIn("fast");
				}
			});
		} else {
			jQuery(".form-feedback")[0].reportValidity();
		}
  });
});

function submitForm(evt) {
    evt.preventDefault();

    return false;
}

function setInseratTypeFelder(type) {
    if(type == "freiwilliger") {
        jQuery("#field_pd_name").attr('required', true);
        jQuery("#field_pd_plz").attr('required', true);
        jQuery("#field_pd_email").attr('required', true);
        jQuery("#field_pd_alter").attr('required', true);
        jQuery("#field_pd_geschlecht_m").attr('required', true);
        jQuery("#field_pd_alter_exakt").attr('required', true);

        //jQuery("#field_pd_institution, #field_pd_ansprechpartner, #field_pd_telefon, #field_pd_plz_institution, #field_pd_ort, #field_pd_strasse, #field_pd_hausnummer").removeAttr('required');
        jQuery("#field_pd_institution, #field_pd_ansprechpartner, #field_pd_plz_institution, #field_pd_ort, #field_pd_strasse, #field_pd_hausnummer, #inserieren_art").removeAttr('required');

        // Art des Inserats ein-/ausblenden
        //jQuery(".initial-hide-for-ehrenamt").show();

        jQuery(".show-for-freiwilliger").show();
        jQuery(".show-for-institution").hide();
    } else {
        jQuery("#field_pd_institution").attr('required', true);
        jQuery("#field_pd_ansprechpartner").attr('required', true);
        jQuery("#field_pd_email").attr('required', true);
        jQuery("#field_pd_telefon").attr('required', true);
        jQuery("#field_pd_plz_institution").attr('required', true);
        jQuery("#field_pd_ort").attr('required', true);
        jQuery("#field_pd_strasse").attr('required', true);
        jQuery("#field_pd_hausnummer").attr('required', true);
        jQuery("#inserieren_art").attr('required', true);

        jQuery("#field_pd_name, #field_pd_plz, #field_pd_alter, #field_pd_geschlecht_m, #field_pd_alter_exakt").removeAttr('required');

        // Art des Inserats ein-/ausblenden
        //jQuery(".initial-hide-for-ehrenamt").hide();

        jQuery(".show-for-freiwilliger").hide();
        jQuery(".show-for-institution").show();
        updatePdfButtonWidth();
    }
}

function setInseratArt(art) {
    if((jQuery("input[name='field_zeitaufwand_intervall']").is(":checked") && jQuery("input[name='field_zeitaufwand_intervall']").val() == "3") || jQuery("input[name='field_zeitaufwand_intervall_einmalig']").is(":checked")) {
        jQuery("input#field_detail_zeitaufwand").hide();
    } else {
        jQuery("input#field_detail_zeitaufwand").show();
    }

  if(art == "fortlaufend") {
    jQuery(".inserat-art-einmalig, .zeitaufwand-einmalig").hide();
    jQuery(".initial-hide-for-ehrenamt, .inserat-art-fortlaufend, .zeitaufwand-fortlaufend").show();
  } else if(art == "einmalig") {
    jQuery(".inserat-art-fortlaufend, .zeitaufwand-fortlaufend").hide();
    jQuery(".initial-hide-for-ehrenamt, .inserat-art-einmalig, .zeitaufwand-einmalig").show();
  } else {
    jQuery(".initial-hide-for-ehrenamt, .inserat-art-fortlaufend, .inserat-art-einmalig, .zeitaufwand-einmalig").hide();
  }
}

function openLayer(type) {
    var closeButtonInside = false;
    jQuery(".layer-inner").hide();
    if(type == "inserat_institution") {
        jQuery(".inner-inserat-institution").show();
    } else if(type == "inserat_freiwilliger") {
        jQuery(".inner-inserat-freiwilliger").show();
    } else if(type == "anfrage_institution") {
        jQuery(".inner-anfrage-institution").show();
    } else if(type == "anfrage_freiwilliger") {
        jQuery(".inner-anfrage-freiwilliger").show();
    } else if(type == "anfrage_kontakt") {
        jQuery(".inner-anfrage-kontakt").show();
    } else if(type == "detail_map") {
        jQuery(".inner-detail-map").show();
    } else if(type == "feedback_institution") {
        jQuery(".inner-feedback_institution").show();
    } else if(type == "feedback_freiwilliger") {
        jQuery(".inner-feedback_freiwilliger").show();
    } else if(type == "layer_auswahl_ehrenamt") {
        jQuery(".inner-ehrenamtauswahl").show();
    } else if(type == "layer_auswahl_freiwillige") {
        jQuery(".inner-freiwilligenauswahl").show();
    } else if(type == "layer_jahreswechsel") {
        jQuery(".inner-jahreswechsel").show();
    } else if(type == "layer_cookie") {
        jQuery(".inner-cookie").show();
    }

    jQuery.magnificPopup.open({
        items: {
            src: '#popup_modal',
        },
        type: 'inline',
        closeBtnInside: closeButtonInside,
        modal: true,
    });
}

function uniqueId() {
    return 'id-' + Math.random().toString(36).substr(2, 16);
}

function updateFotoButtonWidth() {
    var buttonWidth = jQuery(".file-foto").outerWidth();
    jQuery(".file-foto").css({"height": buttonWidth});
}
function updatePdfButtonWidth() {
    var buttonWidth = jQuery(".file-pdf").outerWidth();
    jQuery(".file-pdf").css({"height": buttonWidth});
}

function calculateFilterListeImageHeight() {
    var maxHeight = 0;
    jQuery.each(jQuery("section.modul--filter #filter_view_equalize .foto"), function() {
        var height = jQuery(this).outerHeight();
        if(height > maxHeight)
            maxHeight = height;
    });
    jQuery(".image-container").css({"height": maxHeight});
}

function setStartseiteBoxen() {
    windowWidth = jQuery(window).width();
    if(windowWidth > 1023) {
        setTimeout(function() {
            var boxenHeight = jQuery(".grid-container.boxen").outerHeight(); // container-left
            var boxTopHeight = jQuery(".grid-container.boxen .box-1").outerHeight();
            //var topDistance = 600 - boxenHeight / 2;
            var topDistance = 600 - boxTopHeight / 2 ;
            jQuery(".grid-container.boxen").css({
                "bottom": "auto",
                "top": topDistance,
                "position": "absolute",
                "left": 0,
                "right": 0,
                "margin-top": 0,
            });
            /*
            jQuery(".container-left, .container-right").css({"height": "auto"});
            new Foundation.Equalizer(jQuery(".boxen")).applyHeight();
            */
            var sliderHeight = jQuery(".modul--startseite .slider-container").outerHeight();
            var headerHeight = sliderHeight + boxenHeight - boxTopHeight / 2;
            jQuery(".modul--startseite").css({
              "height": headerHeight,
            });
        }, 300);
    } else {
        jQuery(".boxen").css({
            "position": "relative",
            "top": "auto",
            "left": "auto",
            "right": "auto",
            "bottom": "auto",
            "margin-top": "-123px"
        });
        jQuery(".boxen .box .container-left").css({"height": "84px"});
    }
}

function calculateDownloadIcons() {
    var elementWidth = jQuery(".download-icon").outerWidth();
    jQuery(".download-icon").css({"height": elementWidth});

    var iconHeight = jQuery(".icon-download").outerHeight();
    var iconMarginTop = (elementWidth - iconHeight) / 2;
    jQuery(".icon-download").css({"margin-top": iconMarginTop});

    var infoHeight = jQuery(".download-info").outerHeight();
    var infoMarginTop = (elementWidth - infoHeight) / 2;
    jQuery(".download-info").css({"margin-top": infoMarginTop});
}

function reorderSplitIntroBackground() {
    windowWidth = jQuery(window).width();
    if(windowWidth <= 640) {
        var containerHeight = jQuery(".intro-container").outerHeight();
        var textHeight = jQuery(".text-container").outerHeight();

        var topBgHeight = containerHeight - textHeight - 30;
        jQuery(".bg-left").css({"height": topBgHeight, "width": "100%"});

        var bottomBgHeight = containerHeight - topBgHeight;
        jQuery(".bg-right").css({"height": bottomBgHeight, "width": "100%"});
    } else {
        jQuery(".bg-left").css({"height": "auto", "width": "auto"});
        jQuery(".bg-right").css({"height": "auto", "width": "auto"});
    }
}

function convertSvg() {
	jQuery('img.svg').each(function(){
		var $img = jQuery(this);
		var imgID = $img.attr('id');
		var imgClass = $img.attr('class');
		var imgURL = $img.attr('src');
		jQuery.get(imgURL, function(data) {
			// Get the SVG tag, ignore the rest
			var $svg = jQuery(data).find('svg');
			// Add replaced image's ID to the new SVG
			if(typeof imgID !== 'undefined') {
				$svg = $svg.attr('id', imgID);
			}
			// Add replaced image's classes to the new SVG
			if(typeof imgClass !== 'undefined') {
				$svg = $svg.attr('class', imgClass+' replaced-svg');
			}
			// Remove any invalid XML tags as per http://validator.w3.org
			$svg = $svg.removeAttr('xmlns:a');
			// Replace image with new SVG
			$img.replaceWith($svg);
		}, 'xml');
	});
}

function generateMap(lat, long, config) {
	var map = new google.maps.Map(document.getElementById('map'), config);

	var marker = new SVGMarker({
		map: map,
		position: new google.maps.LatLng(lat, long),
		icon: {
			anchor: new google.maps.Point(14.15,33),
			size: new google.maps.Size(28.3,33),
			url: themePath+'/images/icon-map-pin-yellow.svg'
		}
	});
}

function readCookie(name) {
	var nameEQ = name + "=";
	var ca = document.cookie.split(';');
	for(var i=0;i < ca.length;i++) {
		var c = ca[i];
		while (c.charAt(0)==' ') c = c.substring(1,c.length);
		if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
	}
	return null;
}

function triggerMenu() {
    var menuShown = readCookie("menu");
    var windowWidth = jQuery(window).width();
    if(windowWidth > 667 && (typeof(menuShown) == "undefined" || menuShown == null || menuShown == 0  || menuShown == "")) {
        jQuery(".site-overlay").fadeIn(200);
        jQuery(".menu-button, .menu-container, .menu-button-mobile").toggleClass("is-active");
        setTimeout(function() {
            jQuery(".site-overlay").fadeOut(200);
            jQuery(".menu-button, .menu-container, .menu-button-mobile").toggleClass("is-active");
        }, 2000);

        var theDate = new Date();
		var oneYearLater = new Date( theDate.getTime() + 31536000000 );
		var expiryDate = oneYearLater.toGMTString();
		document.cookie = "menu=1;expires="+expiryDate+";path=/;secure;";
    }
}

window.onload = function() {
    setTimeout(function() {
        new Foundation.Equalizer(jQuery("#filter_equalizer_global")).applyHeight();
    }, 500);
};
